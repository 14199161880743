import React, { FC, useState } from 'react'
import { InterbankExchangeRate } from '@framework/types/home';
import Image from 'next/image';

interface BankHoursProps {
    bank: string
    exchangeBanks : InterbankExchangeRate
    showTime : boolean
    page: string
    currency: string
    valueCal: number
}

type Bank = {
    name: string;
    icon: string;
  };

const BankHours: FC<BankHoursProps> = ({
    bank,
    exchangeBanks,
    showTime,
    page,
    valueCal,
    currency
}) => {
    
    const [showTime2, setShowTime] = useState(false);

  const handleClick = () => {
    setShowTime(!showTime2)
  }

  const banks = [
    {
        name: 'caja',
        buyCost: exchangeBanks?.caja?.compra,
        sellCost: exchangeBanks?.caja?.venta
    },
    {
        name: 'scotia',
        buyCost: exchangeBanks?.scotiabank.buy?.cost,
        sellCost: exchangeBanks?.scotiabank.sale?.cost
    },
    {
        name: 'bcp',
        buyCost: exchangeBanks?.bcp.buy?.cost,
        sellCost: exchangeBanks?.bcp.sale?.cost
    },
    {
        name: 'interbank',
        buyCost: exchangeBanks?.Interbank.buy?.cost,
        sellCost: exchangeBanks?.Interbank.sale?.cost
    },
    {
        name: 'BN',
        buyCost: exchangeBanks?.nacion.buy?.cost,
        sellCost: exchangeBanks?.nacion.sale?.cost
    },
    {
        name: 'bbva',
        buyCost: exchangeBanks?.bbva.buy?.cost,
        sellCost: exchangeBanks?.bbva.sale?.cost
    }
  ];


  const bankInfo = banks?.find(bankInfo => bankInfo.name === bank);

  const banksImage: Bank[] = [
    { name: 'caja', icon: '/icons/huancayo.svg'},
    { name: 'scotia', icon: '/icons/scotiabank.svg' },
    { name: 'bcp', icon: '/icons/bcp.svg' },
    { name: 'interbank', icon: '/icons/interbank.svg' },
    { name: 'BN', icon: '/icons/bn.svg' },
    { name: 'bbva', icon: '/icons/bbva.svg' },
  ];

  const bankIcon = banksImage.find((b) => b.name === bank)?.icon;
    if (!bankInfo) return null;
    const resultUSD = (valueCal * parseFloat(bankInfo?.buyCost ?? 0)).toFixed(2);
    const formattedResultUSD = 'S/' + parseFloat(resultUSD).toLocaleString('en-US');

    const resultPEN = (valueCal / parseFloat(bankInfo.sellCost)).toFixed(2);
    const formattedResultPEN = '$' + parseFloat(resultPEN).toLocaleString('en-US');


  return(
    <div>
        <div className={`flex py-4 justify-between h-[72px] ${bank === 'bcp' || bank === 'BN' ? 'bg-gray1' : '' }`}>
            <div className="flex align-middle w-20 md:w-60">
                <Image src={bankIcon ? bankIcon : ''} alt={bank} width={bankIcon?.includes('bbva') ? '80' : '120'} height={'30'} />
            </div>
            <div className={`${page == 'cambioOnline' ? 'hidden' : 'flex'}`}>
                {showTime || showTime2 ? 
                <button className="ml-2 flex align-middle" onClick={handleClick} aria-label={'button_arrow_' + bank}>
                        <span className="icon icon-arrow my-auto" />  
                </button>
                :
                <button className="ml-2 flex align-middle" onClick={() => setShowTime(!showTime2)} aria-label={'button_arrow2_' + bank}>
                        <span className="icon icon-arrow rotate-180 my-auto " />  
                </button>
                }
            </div>
            <div className={page == 'cambioOnline' ? ' hidden md:flex': 'hidden'}>
                <div className='hidden md:flex justify-center '>
                    <div className="flex justify-center text-xs text-black md:mx-10 md:w-60">
                        <div className="">
                            <div className="flex justify-between">
                                <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                                <p className="w-24 flex justify-start">9 am - 5 pm</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="w-9 flex justify-end mb-1">S</p>
                                <p className="w-24 flex justify-start">9 am - 1 pm</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { page == 'home' &&
                <div className={showTime == true || showTime2 == true ? "content show" : " content" }>
                    <div className='hidden md:flex justify-center '>
                        <div className="w-1/2 flex justify-center text-xs text-primary md:mx-10 md:w-60">
                            <div className="">
                                <div className="flex justify-between">
                                    <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                                    <p className="w-24 flex justify-start">9 am - 5 pm</p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="w-9 flex justify-end mb-1">S</p>
                                    <p className="w-24 flex justify-start">9 am - 1 pm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {bank && (
                <div className='relative md:hidden'>
                  <a href={'https://cuentadigital.cajahuancayo.com.pe/#/apertura/cuenta-corriente/step1'} target="_blank" rel="noreferrer nofollow">
                    <div
                      className={`${bank !== 'caja' ? 'hidden' : 'bg-[#ED1C24] hover:shadow-lg capitalize rounded-full px-4 h-8 text-center'} `}
                    >
                      <h4 className="text-white text-sm leading-8">Cambiar</h4>
                    </div>
                  </a>
                </div>
            )}
            <div className="flex items-center text-xl w-40 md:w-[320px] justify-around md:justify-center ">
            {bankInfo && (
                <>
                            {bank && (
                <div className='relative hidden md:flex'>
                  <a href={'https://cuentadigital.cajahuancayo.com.pe/#/apertura/cuenta-corriente/step1'} target="_blank" rel="noreferrer nofollow">
                    <div
                      className={`${bank !== 'caja' ? 'hidden' : 'bg-[#ED1C24] hover:shadow-lg capitalize rounded-full px-4 h-8 text-center'} `}
                    >
                      <h4 className="text-white text-sm leading-8">Cambiar</h4>
                    </div>
                  </a>
                </div>
            )}
                    <p className={`${currency === 'PEN' ? 'hidden' : bank == 'caja' ? 'text-2xl md:w-40 flex justify-start md:ml-4' : 'text-2xl md:w-40 flex justify-end'}`}>
                    {currency === 'USD' ? formattedResultUSD : parseFloat(bankInfo.buyCost).toFixed(3)}
                    </p>
                    <p className={`${currency === 'USD' ? 'hidden' : 'text-2xl md:w-40 flex justify-end'}`}>
                    {currency === 'PEN' ? formattedResultPEN: parseFloat(bankInfo.sellCost).toFixed(3)}
                    </p>
                </>
                )}
            </div>
        </div>
        <div className={page == 'cambioOnline' ? ' md:hidden': 'hidden'}>
        <div className='flex md:hidden justify-start border-t-[1px] border-[#DADADA]'>
                <div className="w-1/2 flex justify-center text-xs text-primary md:mx-10 md:w-60">
                    <div className=" my-3">
                        <div className="flex justify-between">
                            <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                            <p className="w-24 flex justify-start">9 am - 5 pm</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="w-9 flex justify-end mb-1">S</p>
                            <p className="w-24 flex justify-start">9 am - 1 pm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={showTime == true || showTime2 == true ? "content show" : " content"}>
            <div className='flex md:hidden justify-center '>
                <div className="w-1/2 flex justify-center text-xs text-primary md:mx-10 md:w-60">
                    <div className="">
                        <div className="flex justify-between">
                            <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                            <p className="w-24 flex justify-start">9 am - 5 pm</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="w-9 flex justify-end mb-1">S</p>
                            <p className="w-24 flex justify-start">9 am - 1 pm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export  {BankHours}