import { Interbank } from '@framework/types/home'
import React, { FC, useState } from 'react'
import SunatNew2 from '../icons/SunatNew2'
import ValueQuotation from '../ValueQuotation'
import DolarNew from '../icons/DolarNew'
import s from "./Quotation.module.css";


interface QuotationMobileProps {
    exchangeSunat ?: Interbank,
    exchangeDolar : Interbank, 
    currency: string,
    valueCal: number,
    title: string,
    }

const QuotationMobile: FC<QuotationMobileProps> = ({
    exchangeSunat,
    exchangeDolar,
    currency,
    valueCal,
    title
}) => {
    const [activeTab, setActiveTab] = useState('Paralelo'); // Estado para controlar la pestaña activa
    
    const handleTabClick = (tab: string) => {
      setActiveTab(tab);
    };

  return (
    <div>  
      <div className='border-b-[bg-#E6E0E9] border-b-2 flex'>
        <div>
          <button className={`mx-7 font-medium text-base leading-5 py-3 `} onClick={() => handleTabClick('Paralelo')}>
            Paralelo
          </button>
          {activeTab === 'Paralelo' && <span className={`${s.barra_violeta}`}></span>}
        </div>
        <div>
          <button className={`mx-7 font-medium text-base leading-5 py-3 `} onClick={() => handleTabClick('Sunat')}>
            Sunat
          </button>
          {activeTab === 'Sunat' && <span className={`${s.barra_violeta}`}></span>}
        </div>
      </div>       
        {exchangeSunat && activeTab === 'Sunat' && (
        <div className={`bg-gray1 flex pt-2 pb-4 pl-2`}>
          <div className={`${s.row_cot} py-1 mr-2`}>
            <div className="w-10 my-auto">
            <SunatNew2 fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
            </div>
          </div>
          <div className={`${s.row_cost} w-full `}>
            <ValueQuotation exchangeValue={exchangeSunat} title={title} valueCal={valueCal} currency={currency} type={'sunat'} component='mobile'/>
          </div>
        </div>
        )}
      {exchangeDolar && activeTab === 'Paralelo' && (
        <div className={`bg-gray1 flex pt-2 pb-2 pl-2`}>
          <div className={`${s.row_cot} py-1 mr-2`}>
              <div className="w-10 my-auto">
                <DolarNew fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
              </div>
          </div>
            <div className={`${s.row_cost} w-full`}>
            <ValueQuotation exchangeValue={exchangeDolar} title={title} valueCal={valueCal} currency={currency} type={'dolar'} component='mobile'/>
            </div>
        </div>
        )
      }
    </div>
  )
}

export default QuotationMobile