import { Interbank } from '@framework/types/home';
import React, { FC, useEffect, useRef, useState } from 'react';
import ArrowNormal from '../icons/ArrowNormal';
import Equals from '../icons/Equals';
import 'animate.css';
import s from './ValueQuotation.module.css';

const ArrowUp = () => <span className={s.arrowUp}>&uarr;</span>;
const ArrowDown = () => <span className={s.arrowDown}>&darr;</span>;

export interface ValueQuotationProps {
  exchangeValue: Interbank;
  title: string;
  valueCal: number;
  currency: string;
  type: string;
  section?: string;
  component?: string;
}

export const ValueQuotation: FC<ValueQuotationProps> = ({
  exchangeValue,
  title,
  valueCal,
  currency,
  type,
  section,
  component,
}) => {
  const previousValueRef = useRef<Interbank | null>(null);
  const [buyRateChange, setBuyRateChange] = useState<'increased' | 'decreased' | 'unchanged'>('unchanged');
  const [saleRateChange, setSaleRateChange] = useState<'increased' | 'decreased' | 'unchanged'>('unchanged');
  const [buyAnimation, setBuyAnimation] = useState<string>('');
  const [saleAnimation, setSaleAnimation] = useState<string>('');

  // const audioChange = new Audio('/sound/audioChange.mp3');

  useEffect(() => {
    if (previousValueRef.current) {
      const previousBuyCost = parseFloat(previousValueRef.current.buy.cost);
      const currentBuyCost = parseFloat(exchangeValue.buy.cost);
      const previousSaleCost = parseFloat(previousValueRef.current.sale.cost);
      const currentSaleCost = parseFloat(exchangeValue.sale.cost);

      if (currentBuyCost > previousBuyCost) {
        setBuyRateChange('increased');
        setBuyAnimation('animate__fadeInUp');
        // audioChange.play();
      } else if (currentBuyCost < previousBuyCost) {
        setBuyRateChange('decreased');
        setBuyAnimation('animate__fadeInDown');
        // audioChange.play();
      } else {
        setBuyRateChange('unchanged');
        setBuyAnimation('animate__pulse');
      }

      if (currentSaleCost > previousSaleCost) {
        setSaleRateChange('increased');
        setSaleAnimation('animate__fadeInUp');
        // audioChange.play();
      } else if (currentSaleCost < previousSaleCost) {
        setSaleRateChange('decreased');
        setSaleAnimation('animate__fadeInDown');
        // audioChange.play();
      } else {
        setSaleRateChange('unchanged');
        setSaleAnimation('animate__pulse');
      }
    }
    previousValueRef.current = { ...exchangeValue }; 
  }, [exchangeValue]);

  useEffect(() => {
    if (buyAnimation) {
      const timer = setTimeout(() => setBuyAnimation(''), 1000); 
      return () => clearTimeout(timer);
    }
  }, [buyAnimation]);

  useEffect(() => {
    if (saleAnimation) {
      const timer = setTimeout(() => setSaleAnimation(''), 1000);
      return () => clearTimeout(timer);
    }
  }, [saleAnimation]);

  const resultUSD = (valueCal * parseFloat(exchangeValue?.buy?.cost)).toFixed(2);
  const formattedResultUSD = 'S/' + parseFloat(resultUSD).toLocaleString('en-US');
  const resultPEN = (valueCal / parseFloat(exchangeValue?.sale?.cost)).toFixed(2);
  const formattedResultPEN = '$' + parseFloat(resultPEN).toLocaleString('en-US');

  const valor = (signo: string, variacion: number) =>
    signo === 'equals' ? (
      <div className='flex justify-center items-center'>
        <Equals width={6} height={4} fill={'#4D4B4B'} />
        <p className={`pl-[6px] text-xs text-[#4D4B4B]`}>0.00</p>
      </div>
    ) : signo === 'negative' ? (
      <div className='flex justify-center items-center'>
        <div className='rotate-180'>
          <ArrowNormal width={8} height={5} fill={title === 'tipo de cambio' ? '#DADADA ' : '#80387D'} />
        </div>
        <p className={`${title === 'tipo de cambio' ? 'text-gray' : 'text-third2'} pl-[6px] text-xs`}>{variacion}</p>
      </div>
    ) : (
      <div className='flex justify-center items-center'>
        <ArrowNormal width={8} height={5} fill={title === 'tipo de cambio' ? '#DADADA ' : '#0096A6'} />
        <p className={`${title === 'tipo de cambio' ? 'text-gray' : 'text-secondary'} pl-[6px] text-xs`}>{variacion}</p>
      </div>
    );

  return (
    <div
      className={`flex justify-center ${
        title === 'home' && currency !== '' ? 'flex justify-center ' : ''
      } ${component === 'mobile' ? 'w-full' : ''}`}
    >
      {component === 'mobile' && (
        <span className={`content-center text-sm font-medium ${currency === 'PEN' ? 'hidden' : ''}`}>Compra</span>
      )}
      <div
        className={`${currency === 'PEN' ? 'hidden' : 'block mx-1'} ${
          title === 'home' ? '' : ''
        } block 
          ${valueCal ? 'w-[160px] ' : 'w-[70px]'}
          ${component === 'mobile' ? 'w-full ' : ''}
          ${buyRateChange === 'increased' ? s.text_green : buyRateChange === 'decreased' ? s.text_red : ''}`}
      >
        <div className={`${s.valueContainer} ${buyRateChange === 'increased' ? s.valueContainerGreen : buyRateChange === 'decreased' ? s.valueContainerRed : ''}`}>
          {/* {buyRateChange === 'increased' && <ArrowUp />}
          {buyRateChange === 'decreased' && <ArrowDown />} */}
          <p
            className={`animate__animated ${buyAnimation} ${s.text} 
              ${formattedResultUSD.length > 9 ? 'text-lg' : section ? 'text-xl md:text-2xl' : 'text-2xl'} ${
              component === 'mobile' ? 'text-lg py-1 md:py-0 md:text-2xl' : 'text-2xl'
            } h-8`}
          >
            {currency === 'USD' ? formattedResultUSD : parseFloat(exchangeValue?.buy?.cost).toFixed(3)}
          </p>
        </div>
        <span className='w-full'>{valor(exchangeValue?.buy?.signal, exchangeValue?.buy?.variation)}</span>
      </div>
      {component === 'mobile' && formattedResultPEN && (
        <span className={`content-center text-sm font-medium ${currency === 'USD' ? 'hidden' : ''}`}>Venta</span>
      )}
      <div
        className={`${currency === 'USD' ? 'hidden ' : 'block mx-1 '} ${title === 'home' ? ' ' : ''}   
          ${valueCal ? 'w-[160px]' : 'w-[70px]'}
          ${component === 'mobile' ? 'w-full' : ''}
          ${saleRateChange === 'increased' ? s.text_green : saleRateChange === 'decreased' ? s.text_red : ''}`}
      >
        <div className={`${s.valueContainer} ${saleRateChange === 'increased' ? s.valueContainerGreen : saleRateChange === 'decreased' ? s.valueContainerRed : ''}`}>
          {/* {saleRateChange === 'increased' && <ArrowUp />}
          {saleRateChange === 'decreased' && <ArrowDown />} */}
          <p
            className={`animate__animated ${saleAnimation} ${s.text} 
              ${formattedResultPEN.length > 9 ? 'text-lg md:text-2xl ' : section ? 'text-xl md:text-2xl' : 'text-2xl'} ${
              component === 'mobile' ? 'text-lg py-1 md:py-0 md:text-2xl' : 'text-2xl'
            } h-8`}
          >
            {currency === 'PEN' ? formattedResultPEN : parseFloat(exchangeValue?.sale?.cost).toFixed(3)}
          </p>
        </div>
        <span>{valor(exchangeValue?.sale?.signal, exchangeValue?.sale?.variation)}</span>
      </div>
    </div>
  );
};

export default ValueQuotation;
