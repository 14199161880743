/** @format */

import { Interbank } from '@framework/types/home';
import React, { FC } from 'react';
import ArrowNormal from '../icons/ArrowNormal';
import Equals from '../icons/Equals';
import s from './ValueQuotation.module.css';

export interface ValueQuotationProps {
	exchangeValue: Interbank;
	title: string;
	valueCal: number;
	currency: string;
	type: string;
	section?: string;
	component?: string;
}

export const ValueQuotation: FC<ValueQuotationProps> = ({
	exchangeValue,
	title,
	valueCal,
	currency,
	type,
	section,
	component,
}) => {
	const resultUSD = (valueCal * parseFloat(exchangeValue?.buy?.cost)).toFixed(
		2
	);
	const formattedResultUSD =
		'S/' + parseFloat(resultUSD).toLocaleString('en-US');
	const resultPEN = (
		valueCal / parseFloat(exchangeValue?.sale?.cost)
	).toFixed(2);
	const formattedResultPEN =
		'$' + parseFloat(resultPEN).toLocaleString('en-US');
	const valor = (signo: string, variacion: number) =>
		signo == 'equals' ? (
			<div className='flex justify-center items-center'>
				<Equals
					width={6}
					height={4}
					fill={'#4D4B4B'}
				/>
				<p
					className={`pl-[6px] text-xs text-[#4D4B4B]`}>
					0.00
				</p>
			</div>
		) : signo == 'negative' ? (
			<div className='flex justify-center items-center'>
				<div className='rotate-180'>
					<ArrowNormal
						width={8}
						height={5}
						fill={
							title == 'tipo de cambio' ? '#DADADA ' : '#80387D'
						}
					/>
				</div>
				<p
					className={`${
						title == 'tipo de cambio' ? 'text-gray' : 'text-third2'
					} pl-[6px] text-xs`}>
					{variacion}
				</p>
			</div>
		) : (
			<div className='flex justify-center items-center'>
				<ArrowNormal
					width={8}
					height={5}
					fill={title == 'tipo de cambio' ? '#DADADA ' : '#0096A6'}
				/>
				<p
					className={`${
						title == 'tipo de cambio'
							? 'text-gray'
							: 'text-secondary'
					} pl-[6px] text-xs`}>
					{variacion}
				</p>
			</div>
		);
	return (
		<div
			className={`flex justify-around ${
				title === 'home' && currency !== ''
					? 'flex justify-center '
					: ''
			} ${component === 'mobile' ? 'w-full' : ''}`}>
					{component === 'mobile' && (
						<span className={`content-center text-sm font-medium ${currency === 'PEN' ? 'hidden' : ''}`}>Compra</span>
					)}			
				<div className={`${currency === 'PEN' ? 'hidden' : 'block mx-2'} ${
					title === 'home' ? '' : ''
				} block mx-2  
				${valueCal ? 'w-[160px] ': 'w-[60px]' }
				${component === 'mobile' ? 'w-full ' : ''}
				`}
				>
				{exchangeValue && (
					
					<p
						className={`${s.text} 
						${formattedResultUSD.length > 9 ? 'text-lg' : section ? 'text-xl md:text-2xl' : 'text-2xl'} ${component === 'mobile' ? 'text-lg py-1 md:py-0 md:text-2xl' : 'text-2xl'}
						h-8 `} >
						{currency === 'USD'
							? formattedResultUSD
							: parseFloat(exchangeValue?.buy?.cost).toFixed(3)
						}
					</p>
				)}
				<span className='w-full'>
					{valor(
						exchangeValue?.buy?.signal,
						exchangeValue?.buy?.variation
					)}
				</span>
			</div>
			{component === 'mobile'&& formattedResultPEN && (
				<span className={`content-center text-sm font-medium ${currency === 'USD' ? 'hidden' : ''}`}>Venta</span>
			)}
			<div
				className={`${currency === 'USD' ? 'hidden ' : 'block mx-2 '} ${
					title === 'home' ? ' ' : ''
				} mx-2  
				${valueCal ? 'w-[160px]': 'w-[60px]'}
				${component === 'mobile' ? 'w-full' : ''}
				`} >
				{exchangeValue && (
					<p
						className={`${s.text} 
						${formattedResultPEN.length > 9 ? 'text-lg md:text-2xl ' : section ? 'text-xl md:text-2xl' : 'text-2xl'} ${component === 'mobile' ? 'text-lg py-1 md:py-0 md:text-2xl' : 'text-2xl'}
						h-8 `}>
							{currency === 'PEN'
							? formattedResultPEN
							: parseFloat(exchangeValue?.sale?.cost).toFixed(3)
							}
					</p>
				)}
				<span>
					{valor(
						exchangeValue?.sale?.signal,
						exchangeValue?.sale?.variation
					)}
				</span>
			</div>
		</div>
	);
};

export default ValueQuotation;